.title {
  font-size: 2rem; /* Font size */
  font-weight: bold; /* Font weight */
  color: #ffffff; /* Text color */
  background: linear-gradient(
    45deg,
    #00aaff,
    #0077ff
  ); /* Gradient background */
  -webkit-background-clip: text; /* Clip background to text */
  -webkit-text-fill-color: transparent; /* Make text color transparent to show gradient */
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2); /* Text shadow */
  border-radius: 10px; /* Border radius */
  padding: 4px; /* Padding */
  display: inline-block; /* Inline block display */
  transition: transform 0.3s, text-shadow 0.3s; /* Transition effects */
}

.title:hover {
  transform: scale(1.1); /* Scale effect on hover */
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4); /* Enhanced shadow on hover */
}
